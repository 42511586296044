import axios from "axios";
import { getToken } from "../utils/localStorage";

export const posterListController = async (page, size, type) => {
  const endPoint = `/api/posters?status=${type}&page=${page}&size=${size}`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const posterDetailController = async (id, type = "WAIT") => {
  const endPoint = `/api/posters/${id}?status=${type}`;

  const token = getToken();

  try {
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const acceptPosterController = async (id) => {
  const endPoint = "/api/admins/posters/approve";

  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      {
        posterIds: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const deletePosterController = async (id) => {
  const endPoint = `/api/posters/${id}`;

  const token = getToken();

  try {
    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateArtistController = async (id, imageUrls) => {
  const endPoint = `/api/admins/posters/${id}`;
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      {
        imageUrls,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateArtistImageController = async (id, imageUrls) => {
  const endPoint = `/api/admins/posters/${id}`;
  const token = getToken();

  try {
    const response = await axios.post(
      endPoint,
      {
        imageUrls,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const createOrUpdatePosterController = async (id, data) => {
  const endPoint = id !== undefined ? `/api/posters/${id}` : "/api/posters";
  const token = getToken();
  try {
    if (id) {
      const response = await axios.post(endPoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } else {
      const response = await axios.post(endPoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    console.log(err.response);
    return err;
  }
};
